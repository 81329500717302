<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh;"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="'Dashboard'"></BaseHeader> -->

      <div class="my-2">
        <!-- <div class="row">

            <div v-for="(stat, index) in orderStats"
                      :key="`${index}stats`"
                      
                       class="col-lg-3 col-md-6">
                <div class="card">
                    <div class="card-body">
                      <router-link :to="{ name: 'OrderListing', params: { status: stat.value } }" >
                          <div class="row">
                              <div class="col-8">
                                <h2>1</h2>
                                  <h4>Pending</h4>
                              </div>
                              <div class="col-4">
                                <h6 style="font-size: 48px;margin: 10px 0 0 0;"><i class="mdi mdi-alert-octagram text-danger"></i></h6>
                              </div>
                          </div>
                      </router-link>
                    </div>
                </div>
            </div>
            
        </div> -->
        <div class="row">
          <div class="col-sm-8 col-12">
            <div  class="card rounded shadow">
              <div class="card-header">
                <h2 class="card-title">Order stats</h2>
              </div>

              <div class="table-responsive table-striped">
                <table class="table text-nowrap">
                  <tbody>
                    <tr
                      v-for="(stat, index) in orderStats"
                      :key="`${index}stats`"
                      v-if="stat.no"
                    >
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <router-link :to="{ name: 'OrderListing', params: { status: stat.value } }"
                              class="btn rounded-round btn-icon btn-sm"
                              :class="`bg-${stat.color}-400`"
                            >
                              <span class="letter-icon">{{
                                stat.name.charAt(0) || ""
                              }}</span>
                            </router-link>
                          </div>
                          <div>
                            <router-link :to="{ name: 'OrderListing', params: { status: stat.value } }"
                              class="
                                text-default
                                font-weight-semibold
                                letter-icon-title
                              "
                              >{{ stat.name || "" }}</router-link
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="text-muted   font-size-sm"
                          ><div class="text-muted font-size-sm">
                            <router-link :to="{ name: 'OrderListing', params: { status: stat.value } }"
                              class="badge px-3 py-2 badge-pill"
                              :class="`bg-${stat.color || 'dark'}`"
                              >{{ stat.value || "" }}</router-link
                            >
                          </div></span
                        >
                      </td>
                      <td>
                        <span
                          class="font-weight-bold"
                          style="font-size: 1rem"
                          >{{ stat.no || "" }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- share link to social media -->
            <base-share></base-share>


          </div>
          <div class="col-sm-4 col-12">
            <div style="position: relative!important; " class="card  rounded shadow">
              <div class="card-body">
                <span  class="card-title font_4 font-weight-bold ">Wallet Balance</span>
                <h1 style="font-size: 42px;" class="font-weight-bold text-success">
                  ${{ wallet_balance || "0.0" }}
                </h1>
                <button
                
                  class="btn btn-success"
                  data-toggle="modal"
                  data-target="#walletModal"
                >
                  Topup Wallet
                </button>

                <img class="walletimage" src="/orders/walletimage.png" alt="wallet image" >


              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Tooltip on left">
  Tooltip on left
</button>

<button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
  Tooltip on top
</button> -->
    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup></WalletTopup>
    </BaseModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";

export default {
  name: "Dashboard",
  components: {
    WalletTopup,
  },
  computed: {
    ...mapState("dashboard", ["wallet_balance", "orderStats"]),
    ...mapState("auth", ["user"]),
    siteLink() {
      return this.user.referral.link || {};
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions("dashboard", ["_getWalletBalance", "_getOrderStats"]),
    copyToClipboard(link) {
      document.execCommand("copy");
      alert("Referral link copied to clipboard..");
    },

  },

  async mounted() {
    this.loading = true;
    await this._getWalletBalance().catch((err) => console.log(err));
    await this._getOrderStats().catch((err) => console.log(err));
    
    
    this.loading = false;
  },
};
</script>
